<template>
    <div>
        <h2><router-link :to="{ name: 'stock' }">สต็อก</router-link> > นำเข้าข้อมูลสต็อค</h2>
        <v-card class="mt-4">
          <v-card-title>นำเข้าไฟล์</v-card-title>

          <v-card-body>
            <v-container>
              <v-row no-gutters>
                <v-col>
                  <v-form v-model="isFormValid">
                    <v-file-input
                      label="File input"
                      outlined
                      dense
                      v-model="file"
                      :rules="[validators.required]"
                      show-size
                      hint="ไฟล์ xls หรือ xlsx ขนาดไม่เกิน 10MB"
                      persistent-hint
                      accept=".xls, .xlsx"
                    ></v-file-input>
                  </v-form>                  
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-radio-group
                    v-model="forceReplace"
                    row
                  >
                    <v-radio
                      label="อิงข้อมูลจากไฟล์เป็นหลัก"
                      :value="true"
                    ></v-radio>
                    <v-radio
                      label="อิงข้อมูลจากการเปลี่ยนแปลงเป็นหลัก"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>            
          </v-card-body>

          <v-card-actions>              
              <v-btn
                color="primary"               
                large
                :loading="sendingData"
                @click="submitForm"
                :disabled="!isFormValid"
                v-if="$store.getters['auth/GET_ABILITY'].can('update', 'Inventory')"
              >
                นำเข้า
              </v-btn>
              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>              
            </v-card-actions>
        </v-card>

        <v-card class="mt-4">
            <v-card-title>ประวัติการนำเข้าไฟล์สินค้า</v-card-title>

            <v-card-body>
                <v-simple-table style="width: 100%;">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                สร้างเมื่อ
                            </th>
                            <th class="text-left">
                                อัพเดธล่าสุด
                            </th>
                            <th class="text-left">
                                ไฟล์
                            </th>
                            <th class="text-left">
                                สถานะ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in list"
                            :key="item.id"
                        >
                            <td>{{ item.createdAt }}</td>
                            <td>{{ item.updatedAt }}</td>
                            <td>
                                <template v-if="item.status == 'failed'">
                                    {{ item.message }}
                                </template>
                                <template v-else-if="item.status == 'success'">
                                    <a :href="item.URL">{{ item.name }}</a>
                                </template>
                                <template v-else>
                                    กำลังประมวลผล
                                </template>          
                            </td>
                            <td>{{ item.status }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card-body>            
        </v-card>
    </div>
</template>
<script>
import { required } from '@/@core/utils/validation'
import { BaseFile } from '@/classes/file/BaseFile'
import { asyncGet, asyncPost } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
export default {
  data() {
    return {
      list: [],
      file: null,
      forceReplace: true,
      sendingData: false,
      validators: { required },
      isFormValid: false,
    }
  },
  async created() {
    if (!this.$store.getters['auth/GET_ABILITY'].can('update', 'Inventory')) {
      return this.$router.go(-1)
    }

    this.$store.commit('app/SET_GLOBAL_LOADING', true)

    try {
      const response = await asyncGet('/inventory/importedList')

      this.list = response.map(item => new BaseFile(item))
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }

    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        await asyncPost('/inventory/import', { file: this.file, forceReplace: this.forceReplace })

        const response = await asyncGet('/inventory/importedList')

        this.list = response.map(item => new BaseFile(item))
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.sendingData = false
    },
  },
}
</script>